import footerIcon from '../../assets/footer-icon.png';
import logo from '../../assets/logo.png';
import styles from './styles.module.css';

function Footer() {
  return (
    <footer className={styles.footer}>
        <div className={styles.container}>
            <div className={styles.descriptionContainer}>
              <div className={styles.identityContainer}>
                <img src={footerIcon} className={styles.footerIcon} />
                <img src={logo} className={styles.logo} />
              </div>
              <p className={styles.description}>7498 (+2 completely unique) algorithmically generated, disgusting and collectible zombie-invaders with proof of ownership stored on the Solana blockchain.</p>
            </div>
            <div className={styles.linksContainer}>
              <div className={styles.block}>
                  <h3>Social links</h3>
                  <a className={styles.link} href="https://discord.gg/Q8sAHdD6" target="_blank" rel="noopener noreferrer">Discord</a>
                  <a className={styles.link} href="https://twitter.com/z_invaders" target="_blank" rel="noopener noreferrer">Twitter</a>
                  <a className={styles.link} href="https://www.instagram.com/z_invaders/" target="_blank" rel="noopener noreferrer">Instagram</a>
              </div>
              <div className={styles.block}>
                  <h3>Marketplaces</h3>
                  <a className={styles.link} href="https://magiceden.io/" target="_blank" rel="noopener noreferrer">Magic eden <i clasName={styles.soonText}>(soon)</i></a>
                  <a className={styles.link} href="https://solanart.io/" target="_blank" rel="noopener noreferrer">Solanart <i clasName={styles.soonText}>(soon)</i></a>
                  <a className={styles.link} href="https://opensea.io/" target="_blank" rel="noopener noreferrer">Opensea <i clasName={styles.soonText}>(soon)</i></a>
              </div>
            </div>
        </div>
    </footer>
  );
}

export default Footer;
