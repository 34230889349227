import { useState } from 'react';
import chevron from '../../../assets/chevron.svg';
import styles from './styles.module.css';

function Collapsible(props) {
    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = (isOpen) => setIsOpen(!isOpen);
    return (
      <div className={`${styles.wrapper} ${isOpen ? styles.open : ''}`}>
          <div className={styles.header} onClick={() => toggleIsOpen(isOpen)}>
              {props.heading}
              <img 
                className={`${styles.chevronItem} ${isOpen ? styles.chevronRotated : ''}`}
                src={chevron} 
            />
            </div>
          <div className={styles.body}>{props.body}</div>
      </div>
    );
  }

export default Collapsible;
