import Collapsible from './collapsible';
import styles from './styles.module.css';

function FaqBlock() {
    const faq = [
        {
            heading: "What's the mint cost?",
            body: "1 SOL for every item."
        },
        {
            heading: "What Solana wallets do we support?",
            body: "We support Phantom, Solflare, Slope and Sollet wallets for our desktop version. For mobile we support Phantom only."
        },
        {
            heading: "How many z-invaders will there be?",
            body: "The full division of z-invaders consists of 7500 members"
        },
        {
            heading: "Where do you plan to spend the money?",
            body: "We're going to send 70% to the Armed Forces of Ukraine via different charity foundations. We are starting with the \"Save Life Foundation\". We'll do a report for every penny we send. The remaining 30% will go towards marketing, paying our running costs. Our next plan is to create a collection with heroes that are fighting with z-invaders and gamificate it. If there's anything left, we will spend the money on the biggest party possible to celebrate our future victory.",
        },
        {
            heading: "Will Ukraine win the war?",
            body: "Of course we will. With your support we can do it faster ;)"
        }
        // TO-DO: questions to cover after the launch
        // Where royalty goes?
        // Where to buy? (platforms we're published at)
    ]
    return (
      <div id="faq" className={styles.wrapper}>
          <div className={styles.container}>
              <div className={styles.headingBlock}>
                  <span className={styles.subheading}>ANY QUESTIONS?</span>
                  <h1 className={styles.heading}>FAQ</h1>
                  <p className={styles.headingParagraph}>If you have any additional questions contact us in our <a target="_blank" rel="noopener noreferer" className={styles.link} href="https://discord.gg/Q8sAHdD6">Discord</a></p>
              </div>
              {faq.map(faqItem => <Collapsible heading={faqItem.heading} body={faqItem.body} />)}
          </div>
      </div>
    );
  }

export default FaqBlock;
