import './App.css';
import Layout from './components/layout';
import MainBlock from './components/mainBlock';
import RarityBlock from './components/rarityBlock';
import FaqBlock from './components/faqBlock';
import AboutUsBlock from './components/aboutUsBlock';

function App() {
  return (
    <Layout>
      <MainBlock />
      <AboutUsBlock />
      <RarityBlock />
      <FaqBlock />
    </Layout>
  );
};

export default App;
