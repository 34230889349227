// head elements
import band from '../../assets/rarity-pics/head/band.png';
import winterCap from '../../assets/rarity-pics/head/winter-cap.png';
import sailorCap from '../../assets/rarity-pics/head/sailor-cap.png';
import textileCap from '../../assets/rarity-pics/head/textile-cap.png';
import officerCap from '../../assets/rarity-pics/head/officer-cap.png';
import nkvdCap from '../../assets/rarity-pics/head/nkvd-cap.png';
import tankCap from '../../assets/rarity-pics/head/tank-cap.png';
import baseballCap from '../../assets/rarity-pics/head/baseball-cap.png';
import capCamo from '../../assets/rarity-pics/head/cap-camo.png';
import bird from '../../assets/rarity-pics/head/bird.png';
import kevlarHelmet from '../../assets/rarity-pics/head/kevlar-helmet.png';
import ussrHelmet from '../../assets/rarity-pics/head/ussr-helmet.png';
import glasses from '../../assets/rarity-pics/head/glasses.png';
import pelotka from '../../assets/rarity-pics/head/pelotka.png';
import chechenPapakha from '../../assets/rarity-pics/head/chechen-papakha.png';
import ussrPapakha from '../../assets/rarity-pics/head/ussr-papakha.png';
import beretGreen from '../../assets/rarity-pics/head/beret-green.png';
import beretBlue from '../../assets/rarity-pics/head/beret-blue.png';
import brainsOut from '../../assets/rarity-pics/head/brains-out.png';
import kokoshnik from '../../assets/rarity-pics/head/kokoshnik.png';

// face elements
import worms from '../../assets/rarity-pics/face/worms.png';
import gasMask from '../../assets/rarity-pics/face/gas-mask.png';
import tongue from '../../assets/rarity-pics/face/tongue.png';
import glassesFace from '../../assets/rarity-pics/face/glasses.png';
import gag from '../../assets/rarity-pics/face/gag.png';
import foam from '../../assets/rarity-pics/face/foam.png';
import greenFoam from '../../assets/rarity-pics/face/green-foam.png';
import deadMouth from '../../assets/rarity-pics/face/deadmouth.png';
import rat from '../../assets/rarity-pics/face/rat.png';
import oneEyedWithCig from '../../assets/rarity-pics/face/one-eyed-with-cig.png';
import moustacheWithBlueFoam from '../../assets/rarity-pics/face/moustache-with-blue-foam.png';
import moustacheWithGreenFoam from '../../assets/rarity-pics/face/moustache-with-green-foam.png';
import moustache from '../../assets/rarity-pics/face/moustache.png';
import moustacheWithCig from '../../assets/rarity-pics/face/moustache-with-cig.png';
import cossackMoustache from '../../assets/rarity-pics/face/cossack-moustache.png';
import gingerMoustache from '../../assets/rarity-pics/face/ginger-moustache.png';
import beard from '../../assets/rarity-pics/face/beard.png';
import beardWithCig from '../../assets/rarity-pics/face/beard-with-cig.png';
import snake from '../../assets/rarity-pics/face/snake.png';
import piggy from '../../assets/rarity-pics/face/piggy.png';

// shirt
import sweater from '../../assets/rarity-pics/shirt/sweater.png';
import zipShirt from '../../assets/rarity-pics/shirt/zip-shirt.png';
import zipTunic from '../../assets/rarity-pics/shirt/zip-tunic.png';
import tattoo from '../../assets/rarity-pics/shirt/tattoo.png';
import chain from '../../assets/rarity-pics/shirt/chain.png';
import kufiya from '../../assets/rarity-pics/shirt/kufiya.png';
import sweaterTankTop from '../../assets/rarity-pics/shirt/sweater-tank-top.png';
import blueShirt from '../../assets/rarity-pics/shirt/blue-shirt.png';
import grayShirt from '../../assets/rarity-pics/shirt/gray-shirt.png';
import camoTankTop from '../../assets/rarity-pics/shirt/camo-tank-top.png';
import oliveTankTop from '../../assets/rarity-pics/shirt/olive-tank-top.png';
import tacticalVest from '../../assets/rarity-pics/shirt/tactical-vest.png';
import tacticalVestWithCross from '../../assets/rarity-pics/shirt/tactical-vest-with-cross.png';
import belt from '../../assets/rarity-pics/shirt/belt.png';
import molleVest from '../../assets/rarity-pics/shirt/molle-vest.png';
import telogreika from '../../assets/rarity-pics/shirt/telogreika.png';
import bullets from '../../assets/rarity-pics/shirt/bullets.png';
import skeleton from '../../assets/rarity-pics/shirt/skeleton.png';
import blueSailorTankTop from '../../assets/rarity-pics/shirt/blue-sailor-tank-top.png';
import redSailorTankTop from '../../assets/rarity-pics/shirt/red-sailor-tank-top.png';

// jackets

import none from '../../assets/rarity-pics/jacket/none.png';
import basicCoat from '../../assets/rarity-pics/jacket/basic-coat.png';
import backpackSnails from '../../assets/rarity-pics/jacket/backpack-snails.png';
import camoJacketWithBackpack from '../../assets/rarity-pics/jacket/camo-jacket-with-backpack.png';
import darkCamoJacket from '../../assets/rarity-pics/jacket/dark-camo-jacket.png';
import demobJacket from '../../assets/rarity-pics/jacket/demob-jacket.png';
import fluffyWinterJacket from '../../assets/rarity-pics/jacket/fluffy-winter-jacket.png';
import forestCamouflage from '../../assets/rarity-pics/jacket/forest-camouflage.png';
import furCoat from '../../assets/rarity-pics/jacket/fur-coat.png';
import greenJacket from '../../assets/rarity-pics/jacket/green-jacket.png';
import lightCamoJacket from '../../assets/rarity-pics/jacket/light-camo-jacket.png';
import officerCoat from '../../assets/rarity-pics/jacket/officer-coat.png';
import paradeUniform from '../../assets/rarity-pics/jacket/parade-uniform.png';
import ratJacket from '../../assets/rarity-pics/jacket/rats.png';
import sailorJacket from '../../assets/rarity-pics/jacket/sailor-jacket.png';
import toiletSeat from '../../assets/rarity-pics/jacket/toilet-seat.png';
import winterJacket from '../../assets/rarity-pics/jacket/winter-jacket.png';

// base
import basic from '../../assets/rarity-pics/base/basic.png';
import zombieGreen from '../../assets/rarity-pics/base/zombie-green.png';
import madAvocado from '../../assets/rarity-pics/base/mad-avocado.png';
import grayPsychopath from '../../assets/rarity-pics/base/gray-psychopath.png';
import pinkAcne from '../../assets/rarity-pics/base/pink-acne.png';
import radioactiveCyan from '../../assets/rarity-pics/base/radioactive-cyan.png';
import auChicoGolem from '../../assets/rarity-pics/base/au-chico-golem.png';

// background
import blueBackground from '../../assets/rarity-pics/background/blue.png';
import greenBackground from '../../assets/rarity-pics/background/green.png';
import cyanBackground from '../../assets/rarity-pics/background/cyan.png';
import pinkBackground from '../../assets/rarity-pics/background/pink.png';
import tealBackground from '../../assets/rarity-pics/background/teal.png';
import oliveBackground from '../../assets/rarity-pics/background/olive.png';
import turquoiseBackground from '../../assets/rarity-pics/background/turquoise.png';
import mustardBackground from '../../assets/rarity-pics/background/mustard.png';
import burntSiennaBackground from '../../assets/rarity-pics/background/burnt-sienna.png';
import hotlineMiamiBackground from '../../assets/rarity-pics/background/hotline-miami.png';
import mistyMorningBackground from '../../assets/rarity-pics/background/misty-morning.png';
import duskBackground from '../../assets/rarity-pics/background/dusk.png';
import midnightBackground from '../../assets/rarity-pics/background/midnight.png';
import sovietRedBackground from '../../assets/rarity-pics/background/soviet-red.png';

// unique characters
import putin from '../../assets/rarity-pics/unique/putin.png';
import shoygu from '../../assets/rarity-pics/unique/shoygu.png';
import lavrov from '../../assets/rarity-pics/unique/lovegrowth.png';

import styles from './styles.module.css';
import RarityRow from './row';


function RarityBlock() {
  const uniqueCharacters = [
    {
      image: putin,
      name: "Emperor Kupin",
      itemType: "unique"
    },
    {
      image: shoygu,
      name: "Minister of Offence Choy-Ghu",
      itemType: "unique"
    },
    {
      image: lavrov,
      name: "Minister of Lies Lovegrowth",
      itemType: "unique"
    }
  ];

  const backgroundElements = [
    {
      image: blueBackground,
      name: "Blue",
    },
    {
      image: greenBackground,
      name: "Green",
    },
    {
      image: cyanBackground,
      name: "Cyan",
    },
    {
      image: pinkBackground,
      name: "Pink",
    },
    {
      image: tealBackground,
      name: "Teal",
      itemType: "uncommon"
    },
    {
      image: oliveBackground,
      name: "Olive",
      itemType: "uncommon"
    },
    {
      image: turquoiseBackground,
      name: "Turquoise",
      itemType: "uncommon"
    },
    {
      image: mustardBackground,
      name: "Mustard",
      itemType: "uncommon"
    },
    {
      image: burntSiennaBackground,
      name: "Burnt Sienna",
      itemType: "uncommon"
    },
    {
      image: hotlineMiamiBackground,
      name: "Hotline Miami",
      itemType: "rare"
    },
    {
      image: mistyMorningBackground,
      name: "Misty Morning",
      itemType: "rare"
    },
    {
      image: duskBackground,
      name: "Dusk",
      itemType: "rare"
    },
    {
      image: midnightBackground,
      name: "Midnight",
      itemType: "rare"
    },
    {
      image: sovietRedBackground,
      name: "Soviet Red",
      itemType: "rare"
    },
  ];
  const baseElements = [
    {
      image: basic,
      name: "Average zombie Joe",
    },
    {
      image: zombieGreen,
      name: "Zombie Green",
    },
    {
      image: madAvocado,
      name: "Mad Avocado",
    },
    {
      image: grayPsychopath,
      name: "Psychopathic Gray",
      itemType: "uncommon"
    },
    {
      image: pinkAcne,
      name: "Pink Acne",
      itemType: "uncommon"
    },
    {
      image: radioactiveCyan,
      name: "Radioactive Cyan",
      itemType: "uncommon"
    },
    {
      image: auChicoGolem,
      name: "Au Chico Golem",
      itemType: "rare"
    },
  ];
  const jacketElements = [
    {
      image: none,
      name: "None",
    },
    {
      image: basicCoat,
      name: "Basic coat",
    },
    {
      image: darkCamoJacket,
      name: "Dark camo jacket",
    },
    {
      image: fluffyWinterJacket,
      name: "Fluffy winter jacket",
    },
    {
      image: greenJacket,
      name: "Green jacket",
    },
    {
      image: lightCamoJacket,
      name: "Light camo jacket",
    },
    {
      image: sailorJacket,
      name: "Sailor jacket",
    },
    {
      image: winterJacket,
      name: "Winter jacket",
    },
    {
      image: demobJacket,
      name: "Demob jacket",
    },
    {
      image: camoJacketWithBackpack,
      name: "Camo jacket with backpack",
      itemType: "uncommon",
    },
    {
      image: furCoat,
      name: "Fur coat",
      itemType: "uncommon",
    },
    {
      image: paradeUniform,
      name: "Parade uniform",
      itemType: "uncommon",
    },
    {
      image: ratJacket,
      name: "Rat jacket",
      itemType: "uncommon",
    },
    {
      image: backpackSnails,
      name: "Backpack snails",
      itemType: "rare",
    },
    {
      image: officerCoat,
      name: "Officer coat",
      itemType: "rare",
    },
    {
      image: forestCamouflage,
      name: "Forest camouflage",
      itemType: "rare",
    },
    {
      image: toiletSeat,
      name: "Toilet seat",
      itemType: "legendary",
    },
  ];

  const headElements = [
    {
      image: band,
      percent: 80,
      name: "Bandage"
    },
    {
      image: textileCap,
      percent: 5,
      name: "Bandana camo"
    },
    {
      image: baseballCap,
      percent: 5,
      name: "Baseball cap"
    },
    {
      image: capCamo,
      percent: 5,
      name: "Camo cap"
    },
    {
      image: kevlarHelmet,
      percent: 5,
      name: "Kevlar helmet"
    },
    {
      image: ussrHelmet,
      percent: 5,
      name: "USSR helmet"
    },
    {
      image: beretGreen,
      percent: 5,
      name: "Green beret"
    },
    {
      image: beretBlue,
      percent: 5,
      name: "Blue beret"
    },
    {
      image: brainsOut,
      percent: 5,
      name: "Brains out",
      itemType: "uncommon"
    },
    {
      image: tankCap,
      percent: 5,
      name: "Tank helmet",
      itemType: "uncommon"
    },
    {
      image: winterCap,
      percent: 15,
      name: "Ushanka",
      itemType: "uncommon"
    },
    {
      image: officerCap,
      percent: 5,
      name: "Officer cap",
      itemType: "uncommon"
    },
    {
      image: bird,
      percent: 5,
      name: "Bird",
      itemType: "uncommon"
    },
    {
      image: glasses,
      percent: 5,
      name: "Glasses",
      itemType: "uncommon"
    },
    {
      image: nkvdCap,
      percent: 5,
      name: "NKVD cap",
      itemType: "rare"
    },
    {
      image: sailorCap,
      percent: 5,
      name: "Sailor cap",
      itemType: "rare"
    },
    {
      image: pelotka,
      percent: 5,
      name: "Pilotka",
      itemType: "rare"
    },
    {
      image: chechenPapakha,
      percent: 5,
      name: "Chechen papakha",
      itemType: "rare"
    },
    {
      image: ussrPapakha,
      percent: 5,
      name: "USSR papakha",
      itemType: "legendary"
    },
    {
      image: kokoshnik,
      percent: 5,
      name: "Kokoshnik",
      itemType: "legendary"
    },
  ];

  const shirtElements = [
    {
      image: sweater,
      percent: 5,
      name: "Sweater",
      itemType: "common",
    },
    {
      image: zipShirt,
      percent: 5,
      name: "Zip shirt",
    },
    {
      image: sweaterTankTop,
      percent: 5,
      name: "Sweater tank top"
    },
    {
      image: oliveTankTop,
      percent: 5,
      name: "Olive tank top"
    },
    {
      image: tacticalVest,
      percent: 5,
      name: "Tactical vest"
    },
    {
      image: molleVest,
      percent: 5,
      name: "Molle vest"
    },
    {
      image: skeleton,
      percent: 5,
      name: "Skeleton"
    },
    {
      image: blueSailorTankTop,
      percent: 5,
      name: "Blue sailor tank top"
    },
    {
      image: camoTankTop,
      percent: 5,
      name: "Camo tank top",
      itemType: "uncommon"
    },
    {
      image: blueShirt,
      percent: 5,
      name: "Blue shirt",
      itemType: "uncommon"
    },
    {
      image: grayShirt,
      percent: 5,
      name: "Gray shirt",
      itemType: "uncommon"
    },
    {
      image: zipTunic,
      percent: 5,
      name: "Zip tunic",
      itemType: "uncommon"
    },
    {
      image: tacticalVestWithCross,
      percent: 5,
      name: "Tactical vest with cross",
      itemType: "uncommon"
    },
    {
      image: telogreika,
      percent: 5,
      name: "Telogreika",
      itemType: "uncommon"
    },
    {
      image: chain,
      percent: 5,
      name: "Chain",
      itemType: "rare"
    },
    {
      image: kufiya,
      percent: 5,
      name: "Kufiya",
      itemType: "rare"
    },
    {
      image: bullets,
      percent: 5,
      name: "Bullets",
      itemType: "rare"
    },
    {
      image: redSailorTankTop,
      percent: 5,
      name: "Red sailor tank top",
      itemType: "rare"
    },
    {
      image: tattoo,
      percent: 5,
      name: "Tattoo",
      itemType: "legendary",
    },
    {
      image: belt,
      percent: 5,
      name: "Belt",
      itemType: "legendary",
    },
  ]
  const faceElements = [
    {
      image: worms,
      percent: 5,
      name: "Worms"
    },
    {
      image: foam,
      percent: 5,
      name: "Foam"
    },
    {
      image: moustacheWithBlueFoam,
      percent: 5,
      name: "Moustache with blue foam"
    },
    {
      image: moustacheWithGreenFoam,
      percent: 5,
      name: "Moustache with green foam"
    },
    {
      image: moustache,
      percent: 5,
      name: "Moustache"
    },
    {
      image: moustacheWithCig,
      percent: 5,
      name: "Moustache with cig"
    },
    {
      image: gingerMoustache,
      percent: 5,
      name: "Ginger moustache"
    },
    {
      image: beard,
      percent: 5,
      name: "Beard"
    },
    {
      image: beardWithCig,
      percent: 5,
      name: "Beard with cig"
    },
    {
      image: greenFoam,
      percent: 5,
      name: "Green foam",
      itemType: "uncommon"
    },
    {
      image: deadMouth,
      percent: 5,
      name: "Snotface",
      itemType: "uncommon"
    },
    {
      image: cossackMoustache,
      percent: 5,
      name: "Budyonny moustache",
      itemType: "uncommon"
    },
    {
      image: piggy,
      percent: 5,
      name: "Piggy",
      itemType: "uncommon"
    },
    {
      image: tongue,
      percent: 5,
      name: "Tongue",
      itemType: "uncommon"
    },
    {
      image: rat,
      percent: 5,
      name: "Rat",
      itemType: "rare"
    },
    {
      image: snake,
      percent: 5,
      name: "Lizard",
      itemType: "rare"
    },
    {
      image: glassesFace,
      percent: 5,
      name: "Glasses",
      itemType: "rare"
    },
    {
      image: gasMask,
      percent: 5,
      name: "Gas mask",
      itemType: "rare"
    },
    {
      image: oneEyedWithCig,
      percent: 5,
      name: "One-eyed with cig",
      itemType: "legendary"
    },
    {
      image: gag,
      percent: 5,
      name: "Gag",
      itemType: "legendary"
    },
  ];

  return (
    <div id="rarity" className={styles.wrapper}>
        <div className={styles.container}>
            <div className={styles.headingBlock}>
                <span className={styles.subheading}>THE COLLECTION</span>
                <h1 className={styles.heading}>Rarity list</h1>
                <p className={styles.headingParagraph}>Here are all the traits of our beautiful collection</p>
            </div>
            <RarityRow heading="Characters (blurred for intrigue)" items={uniqueCharacters} />
            <RarityRow heading="Background" items={backgroundElements} />
            <RarityRow heading="Base" items={baseElements} />
            <RarityRow heading="Head" items={headElements} />
            <RarityRow heading="Face" items={faceElements} />
            <RarityRow heading="Shirt" items={shirtElements} />
            <RarityRow heading="Jacket" items={jacketElements} />
        </div>
    </div>
  );
}

export default RarityBlock;
