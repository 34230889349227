import zombieTankDriver from '../../assets/villain-image.png';
import clipart from '../../assets/clipart.png';
import styles from './styles.module.css';

function MainBlock() {
  return (
    <div className={styles.container}>
        <img className={styles.heroImage} src={zombieTankDriver} />
        <div className={styles.textBlock}>
            <span className={styles.subHeading}>SOON MIGHT BE RANKED #1 ON MAGICEDEN</span>
            <h1 className={styles.heading}>Help Ukrainians to defend our country</h1>
            <p className={styles.paragraph}>7498 (+2 completely unique) algorithmically generated, disgusting and collectible zombie-invaders with proof of ownership stored on the Solana blockchain.</p>
        </div>
        <img className={styles.clipart} src={clipart} />
    </div>
  );
}

export default MainBlock;
