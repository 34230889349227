import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import logo from '../../assets/logo.png';

import Button from '../button';

function Header() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setScrolled(window.pageYOffset > 1)
      );
    }
  }, []);
  return (
    <header className={`${styles.header} ${scrolled ? styles.scrolled : ''}`}>
        <div className={styles.container}>
          <a href="#main">
            <img className={styles.logo} src={logo} />
          </a>
          <div className={styles.linkContainer}>
            <a target="_blank" href="https://mint.z-invaders.com">
              <Button>Mint</Button>
            </a>
            <a className={styles.menuItem} href="#about-us">About us</a>
            <a className={styles.menuItem} href="#rarity">Rarity</a>
            <a className={styles.menuItem} href="#faq">FAQ</a>
          </div>
        </div>
    </header>
  );
}

export default Header;
