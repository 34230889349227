import styles from './styles.module.css';

import RarityItem from '../item';


function RarityRow(props) {
  return (
    <div className={styles.wrapper}>
        <h2 className={styles.heading}>{props.heading}</h2>
        <div className={styles.itemGrid}>
            {props.items.map((zombie) => <RarityItem key={zombie.name} image={zombie.image} percent={zombie.percent} name={zombie.name} itemType={zombie.itemType} />)}
        </div>
    </div>
  );
}

export default RarityRow;
