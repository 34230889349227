import styles from './styles.module.css';

function RarityElement(props) {
  function getBadge(itemType) {
    if (itemType === 'legendary') {
      return <span className={`${styles.badge} ${styles.legendaryBadge}`}>Legendary</span>
    }

    if (itemType === 'rare') {
      return <span className={`${styles.badge} ${styles.rareBadge}`}>Rare</span>
    }

    if (itemType === 'uncommon') {
      return <span className={`${styles.badge} ${styles.uncommonBadge}`}>Uncommon</span>
    }

    if (itemType === 'unique') {
      return <span className={`${styles.badge} ${styles.unique}`}>Unique</span>
    }
  
    return <span className={`${styles.badge} ${styles.commonBadge}`}>Common</span>
  }
  return (
    <div className={styles.wrapper}>
      <img className={styles.image} src={props.image} loading="lazy" />
      <span className={styles.itemName}>{props.name}</span>
      { getBadge(props.itemType) }
    </div>
  );
}

export default RarityElement;

// import styles from './styles.module.css';

// function RarityElement(props) {
//   console.log(`width:${props.percent}%`, props);
//   return (
//     <div className={styles.wrapper}>
//       <img className={styles.image} src={props.image} loading="lazy" />
//       <span className={styles.itemName}>{props.name}</span>
//       <div className={styles.progressBarBorder}>
//         <div className={styles.progressBar} style={{ width: `${props.percent}%`}}></div>
//       </div>
//       <span className={styles.percentParagraph}>{`${props.percent}% of russian fucks`}</span>
//     </div>
//   );
// }

// export default RarityElement;
