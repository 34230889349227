import styles from './styles.module.css';
import pattern from '../../assets/pattern.png';

function AboutUsBlock() {
    return (
      <div id="about-us" className={styles.wrapper}>
          <div className={styles.container}>
              <div className={styles.headingBlock}>
                  <span className={styles.subheading}>WHAT IS THE Z-INVADERS?</span>
                  <h1 className={styles.heading}>ABOUT US</h1>
                  <p className={styles.headingParagraph}>
                  Their emperor Kupin is famous for threatening other planets, nobody ever took him seriously. These invaders weren't even sure if the invasion was going to happen, so they come dressed in whatever they could find. Incredibly, he ordered them to attack a  peaceful planet, sure that it would be taken in 7 hours.
                  </p>
                  <p className={styles.headingParagraph}>
                  Now the invasion has lasted way longer than originally planned. Month after month their appetite for war grows smaller. They’ve only just realised that they can’t even defend strategically important public toilet, let alone conquer a whole planet.
                  </p>
                  <p className={styles.headingParagraph}>
                  The invasion isn't over and we need your support to help this peaceful planet overcome this brutal and senseless invasion.
                  </p>
              </div>
              <img src={pattern} class={styles.pattern} />
          </div>
      </div>
    );
  }

export default AboutUsBlock;
