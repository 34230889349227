import Header from '../header';
import Footer from '../footer';
import styles from './styles.module.css';

function Layout(props) {
  return (
    <div id="main" className={styles.container}>
      <Header />
      {props.children}
      <Footer />
    </div>
  );
}

export default Layout;
